@import "../common/variables";
@import "../common/mixins";

.glossary-page-wrapper {
  width: 100%;
  height: fit-content;
  min-height: calc(100vh - $header-height);
  max-height: 80vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.glossary-page-section {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 85%;
  max-width: 850px;
  height: fit-content;
  min-height: 70vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  background-color: rgba(265, 265, 265, 0.7);
  border: 0.5px solid #c3c3c3;
  background: linear-gradient(145deg, rgba(265, 265, 265, 0.3), #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.glossary-page-section::-webkit-scrollbar {
  display: none;
}

.letters-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: fit-content;
  width: 98%;
}

.letters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin: 10px;
  overflow-x: scroll;
}

.letters {
  scrollbar-width: 1px;
  scrollbar-color: dimgray transparent;
}

.letters::-webkit-scrollbar {
  height: 4px;
  border-radius: 2px;
}

.letters::-webkit-scrollbar-track {
  background: transparent;
}

.letters::-webkit-scrollbar-thumb {
  background-color: dimgray;
  border-radius: 1px;
  border: 1px solid transparent;
  width: 2px !important;
}

.letter {
  cursor: pointer;
  padding: 10px;
  width: fit-content;
  background-color: rgb(229, 226, 226);
  font-weight: 600;
  background-color: white;
  color: black;
}

.letter:hover,
.letter:active,
.selected-letter {
  background-color: black;
  color: white;
}

.no-data-glossary-message {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: red;
  font-size: 16px;
}

.word-cell:hover {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.MuiToolbar-root {
  width: fit-content !important;
}

@media (max-width: 1024px) {
  .glossary-page-section {
    min-height: 80vh;
  }

  .letters::-webkit-scrollbar {
    height: 2px;
    border-radius: 1px;
  }

  .MuiTablePagination-spacer {
    -webkit-flex: 0 !important;
    flex: 0 !important;
  }

  .MuiToolbar-root {
    width: 150px !important;
  }

  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    width: fit-content !important;
  }
}
