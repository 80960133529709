@import "../common/variables";
@import "../common/mixins";

.tnc-wrapper {
  width: 100%;
  height: fit-content;
  min-height: calc(100vh - $header-height);
  max-height: 80vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.tnc-section {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 85%;
  max-width: 850px;
  height: fit-content;
  min-height: 55vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  background-color: rgba(265, 265, 265, 0.5);
  border: 0.5px solid #c3c3c3;
  background: linear-gradient(145deg, rgba(265, 265, 265, 0.1), #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tnc-section::-webkit-scrollbar {
  display: none;
}
