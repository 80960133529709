@import "../common/variables";
@import "../common/mixins";

.user-page-wrapper {
  width: 100%;
  height: fit-content;
  min-height: calc(100vh - $header-height);
  max-height: 80vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.user-page-section {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 90%;
  max-width: 850px;
  height: fit-content;
  min-height: 70vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  background-color: rgba(265, 265, 265, 0.7);
  border: 0.5px solid #c3c3c3;
  background: linear-gradient(145deg, rgba(265, 265, 265, 0.3), #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.user-page-section::-webkit-scrollbar {
  display: none;
}

.user-name-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 95%;
  margin: 20px auto;
}

.user-name-section > h1 {
  font-size: 32px;
  color: darkslateblue;
  padding-top: 10px;
}

.admin-search-panel > button {
  width: fit-content;
  min-width: 180px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002d62;
  background-color: white;
  outline: none;
  background-color: rgba(265, 265, 265, 0.5);
  border: 0.5px solid #c3c3c3;
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  cursor: pointer;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.admin-search-panel > button:hover {
  color: white;
  background-color: #002d62;
  border: 1px solid white;
}

.warning-text {
  color: red;
  margin-top: 15vh;
  width: 100%;
  text-align: center;
  font-size: 22px;
}

.bulk-data-handle-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 96%;
  height: fit-content;
  flex-wrap: wrap;
}

.upload-section-wrapper {
  width: 250px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.file-upload {
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 5px;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 5px auto;
  min-height: 150px;
}

.file-upload > .MuiSvgIcon-root {
  margin-top: 20px;
}

.file-upload > h3,
p {
  width: 90%;
  margin: 10px auto;
}

.file-upload > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  color: #002d62;
  font-weight: 600;
}

.file-upload > input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.admin-search-panel-container {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.admin-search-panel {
  width: 90%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
}

.admin-search-panel > input {
  max-width: 500px;
  min-width: 250px;
  outline: none;
  border: 1px solid #002d62;
  border-radius: 30px;
  height: 40px;
  padding-left: 20px;
  width: 60%;
  margin: 10px;
  font-size: 20px;
  padding-top: 5px;
  position: relative;
}

.suggestions-page-wrapper {
  width: 100%;
  height: fit-content;
  max-height: 80vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.suggestions-page-section {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 85%;
  max-width: 850px;
  height: fit-content;
  min-height: 10vh !important;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px !important;
  background-color: rgba(265, 265, 265, 0.7);
  border: 0.5px solid #c3c3c3;
  background: linear-gradient(145deg, rgba(265, 265, 265, 0.3), #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.glossary-page-section::-webkit-scrollbar {
  display: none;
}

.image-gallery-container {
  width: 95%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.image-gallery-container > ul > li {
  width: 100%;
}

.image-name {
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .user-name-section > h1 {
    font-size: 16px;
    padding-top: 8px;
  }

  .bulk-data-upload-section > input,
  button {
    max-width: 300px;
  }

  .image-gallery-container {
    justify-content: start;
  }
}

.logoutBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(255, 65, 65);
}

.sign {
  width: 100%;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: white;
}

.logoutText {
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: 0.3s;
  margin-left: 2px;
  margin-top: 5px;
}

.logoutBtn:hover {
  width: 125px;
  border-radius: 40px;
  transition-duration: 0.3s;
}

.logoutBtn:hover .sign {
  width: 25%;
  transition-duration: 0.3s;
  padding-left: 20px;
}

.logoutBtn:hover .logoutText {
  opacity: 1;
  width: 75%;
  transition-duration: 0.3s;
  padding-right: 10px;
}

.logoutBtn:active {
  transform: translate(2px, 2px);
}
