@import "../common/variables";
@import "../common/mixins";

.header-wrapper {
  width: 100%;
  height: $header-height;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-section {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 30%;
  height: 100%;
  color: #002d62;
  font-size: 24px;
  cursor: pointer;
}

.logo-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo-text > div {
  padding-left: 20px;
  padding-top: 20px;
}

.logo-text > div > img {
  height: 120px;
  width: 150px;
}

.nav-section {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 5px 20px;
  width: 70%;
  height: 100%;
}

.header-item {
  padding: 0 1%;
  font-size: 20px;
  color: black;
  font-weight: 600;
}

@media (max-width: 1024px) {
  .logo-section {
    margin-top: 10px;
  }

  .logo-text > div {
    padding-top: 0;
  }

  .logo-text > div > img {
    height: 80px;
    width: 100px;
  }

  .logo-text > h1 {
    padding-left: 15px;
  }

  .header-item {
    padding: 0 3%;
    color: black !important;
  }

  .burger-icon {
    font-size: 38px !important;
    color: black !important;
    padding-right: 3vw !important;
  }

  .drawer-wrapper > .MuiPaper-root {
    width: 200px !important;
  }

  .drawer-option > .MuiTypography-root {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
}

.header-item:hover {
  color: #153d5a;
  cursor: pointer;
}

.active-tab {
  color: red;
}
.active-tab:hover {
  color: red;
  cursor: pointer;
}
