@import "./common/variables";
@import "./common/mixins";

.App {
  @include flex-column-display;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.App::-webkit-scrollbar {
  display: none;
}
