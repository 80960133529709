@import "../common/variables";
@import "../common/mixins";

.competition-wrapper {
  width: 100%;
  height: fit-content;
  min-height: calc(100vh - $header-height);
  max-height: 80vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.competition-section {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 85%;
  max-width: 850px;
  height: fit-content;
  min-height: 55vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2vw;
  background-color: rgba(265, 265, 265, 0.5);
  border: 0.5px solid #c3c3c3;
  background: linear-gradient(145deg, rgba(265, 265, 265, 0.1), #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  h1 {
    text-align: center;
    width: 100%;
  }

  h3 {
    font-weight: bold;
  }

  ol {
    padding-left: 20px;

    li {
      a {
        font-weight: bold;
        font-style: italic;
      }
    }
  }

ul {
      padding-left: 20px;

      li {
        a {
          font-weight: bold;
          font-style: italic;
        }
      }
}

  p {
    font-style: italic;
    font-weight: bold;
  }
}

.competition-section::-webkit-scrollbar {
  display: none;
}
.ranks {
   font-style: italic;
   font-weight: bold;
   color: DodgerBlue;
   text-align: left;
}
.rankDesc {
   font-style: italic;
   font-weight: bold;
}
.button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  .button {
    --main-focus: #2d8cf0;
    --font-color: #323232;
    --bg-color-sub: #dedede;
    --bg-color: #eee;
    --main-color: #323232;
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    background-color: var(--bg-color);
    border-radius: 10px;
    overflow: hidden;
  }

  .button,
  .button__icon,
  .button__text {
    transition: all 0.3s;
  }

  .button .button__text {
    transform: translateX(22px);
    color: var(--font-color);
    font-weight: 600;
    margin-top: 5px;
  }

  .button .button__icon {
    position: absolute;
    transform: translateX(109px);
    height: 100%;
    width: 39px;
    background-color: var(--bg-color-sub);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button .svg {
    width: 20px;
    fill: var(--main-color);
  }

  .button:hover {
    background: var(--bg-color);
  }

  .button:hover .button__text {
    color: transparent;
  }

  .button:hover .button__icon {
    width: 148px;
    transform: translateX(0);
  }

  .button:active {
    transform: translate(3px, 3px);
    box-shadow: 0px 0px var(--main-color);
  }
}
