@import "./common/variables";
@import "./common/mixins";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Kannada:wght@300&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@300&family=Roboto:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans Kannada", sans-serif;
  // font-family: "Baloo Tamma 2", sans-serif;
}

#root,
body {
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  background: $background-color;
  background: -moz-linear-gradient(
    315deg,
    $background-color 10%,
    $white-background 90%
  );
  background: -webkit-linear-gradient(
    315deg,
    $background-color 10%,
    $white-background 90%
  );
  background: linear-gradient(
    315deg,
    $background-color 10%,
    $white-background 90%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$start-color,endColorstr=$white-background,GradientType=1);
  color: $text-color;
  @include flex-column-display;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#root::-webkit-scrollbar {
  display: none;
}
