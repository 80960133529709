@import "../common/variables";
@import "../common/mixins";

.home-wrapper {
  width: 100%;
  height: fit-content;
  min-height: calc(100vh - $header-height);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

// search bar

.search-container {
  margin-top: 10px;
  width: 85%;
  max-width: 500px;
  height: 30px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 2vw;
  background-color: white;
  background: linear-gradient(145deg, #fff7f7, #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  border: 0.5px solid #c3c3c3;
  cursor: pointer;
}

.search-container > form {
  flex: 1;
  margin: 0 10px;
  height: 100%;
  outline: none;
  width: 80%;
  background-color: transparent;
  border: none;
  position: relative;
}

.search-input {
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 20px;
  background-color: transparent;
  border: none;
  padding-top: 3px;
  position: absolute;
  left: 0;
}

.search-input::placeholder {
  font-size: 14px;
  padding-top: -3px;
}

.hide-text {
  z-index: 0;
  color: transparent;
  outline: none;
}

.hide-text:focus {
  caret-color: transparent;
}

.hide-text::selection {
  color: transparent;
}

.override {
  z-index: 10;
  background: transparent;
  cursor: text;
  left: 0px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.caret {
  animation: blink 1s step-end infinite;
  margin-left: -5px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.clear-icon {
  margin-right: 10px;
  color: black;
}

.MuiFormControlLabel-label {
  font-weight: 600 !important;
}

.MuiSwitch-thumb {
  color: green !important;
}

.MuiSwitch-track {
  background-color: rgb(132, 167, 132) !important;
}

// results and no data section

.no-data-found-wrapper,
.search-results-wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 5px 10px;
  width: 85%;
  max-width: 750px;
  height: fit-content;
  min-height: 55vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  background-color: rgba(265, 265, 265, 0.5);
  border: 0.5px solid #c3c3c3;
  background: linear-gradient(145deg, rgba(265, 265, 265, 0.1), #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-data-found-wrapper::-webkit-scrollbar,
.search-results-wrapper::-webkit-scrollbar {
  display: none;
}

.no-data-found-wrapper > img {
  height: 250px;
  max-width: 90%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .no-data-found-wrapper,
  .search-results-wrapper {
    min-height: 65vh;
  }
}

.no-data-text {
  margin-top: 10px;
  padding: 0 10px;
  font-size: 16px;
}

.no-data-text > p > span {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.result-count {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 90%;
  height: fit-content;
  color: darkblue;
  margin: 10px auto;
}

.search-card {
  margin: 10px;
  width: 100%;
  max-width: 730px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 2vw;
}

.search-card:first-child {
  padding: 10px;
}

.searched-word-heading {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  flex-direction: column;
}

.heading-text-with-mic {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
}

.kannada-header-text {
  width: 75%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.header-text > h1 {
  font-size: 28px;
  margin-bottom: 20px;
  color: blue;
  font-weight: 600;
}

.sub-header-text > h1 {
  font-size: 24px;
  margin-bottom: 0;
  color: rgb(59, 90, 91);
  font-weight: 600;
}

.english-header-text {
  width: 75%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.user-action-buttons {
  width: 25%;
}

.user-action-buttons > .MuiButtonBase-root > .MuiSvgIcon-root {
  font-size: 28px !important;
}

.speaker-icon {
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 38px !important;
  cursor: pointer;
  color: darkslategray;
}

.speaker-icon:hover {
  color: cornflowerblue;
}

.kannada-words-suggestion {
  margin-top: 10px;
  width: 85%;
  max-width: 500px;
  height: fit-content;
  max-height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 1vh 2vw;
  background-color: white;
  background: linear-gradient(145deg, #fff7f7, #ffffff);
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  border: 0.5px solid #c3c3c3;
  overflow-y: scroll;
}

.kannada-words-suggestion::-webkit-scrollbar {
  width: 4px;
  border-radius: 5px;
}

.kannada-words-suggestion::-webkit-scrollbar-track {
  background: transparent;
}

.kannada-words-suggestion::-webkit-scrollbar-thumb {
  background-color: rgb(188, 188, 188);
  border-radius: 5px;
  border: 1px solid transparent;
  width: 2px !important;
}

.word-suggestion {
  width: 90%;
  height: fit-content;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;
}

.word-suggestion:hover {
  font-weight: 600;
}

.search-result-section-headers {
  margin-top: 10px;
  width: 100%;
  color: dimgray;
  font-size: 24px;
  margin-left: 25px;
}

.searched-result-description,
.searched-result-description > ol {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 25px;
}

.searched-result-description {
  margin: 10px auto;
  height: fit-content;
}

.searched-result-description > ol {
  align-items: start;
  width: 90%;
  height: fit-content;
  list-style: disc;
  word-break: normal;
}

.searched-result-description > ol > li,
.description-text {
  font-size: 22px;
}

.searched-result-description > ol > li > a {
  font-size: 18px;
  width: 90%;
  word-break: break-all;
}

.description-text {
  align-items: start;
  width: 100%;
  height: fit-content;
  margin-left: 25px;
}

.image-section {
  width: 90%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.image-section > img {
  width: 90%;
  max-width: 500px;
  height: auto;
  object-fit: contain;
  margin: 10px;
}

.searched-db-val-result-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2%;
  border-radius: 0px;
  width: 96%;
  height: fit-content;
}
.edit-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
  text-decoration: none !important;
}

.edit-svgIcon {
  width: 17px;
  transition-duration: 0.3s;
}

.edit-svgIcon path {
  fill: white;
}

.edit-button:hover {
  width: 120px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.edit-button:hover .edit-svgIcon {
  width: 20px;
  transition-duration: 0.3s;
  transform: translateY(60%);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.edit-button::before {
  display: none;
  content: "Edit";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
  padding-top: 5px;
}

.edit-button:hover::before {
  display: block;
  padding-right: 10px;
  font-size: 13px;
  opacity: 1;
  transform: translateY(0px);
  transition-duration: 0.3s;
}

.delete-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.delete-svgIcon {
  width: 15px;
  transition-duration: 0.3s;
}

.delete-svgIcon path {
  fill: white;
}

.delete-button:hover {
  width: 90px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.delete-button:hover .delete-svgIcon {
  width: 20px;
  transition-duration: 0.3s;
  transform: translateY(60%);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.delete-button::before {
  display: none;
  content: "Delete";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
  padding-top: 5px;
}

.delete-button:hover::before {
  display: block;
  padding-right: 10px;
  font-size: 13px;
  opacity: 1;
  transform: translateY(0px);
  transition-duration: 0.3s;
}

.header-item {
  background-color: transparent;
  outline: none;
  border: none;
}

.youtube-player {
  width: 90%;
  aspect-ratio: 4/3;
  border: none;
}

@media (max-width: 1024px) {
  .word-suggestion {
    width: 80%;
  }

  .header-text > h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: blue;
    font-weight: 600;
    margin-left: 5px;
  }

  .sub-header-text > h1 {
    font-size: 24px;
    margin-bottom: 0;
    color: rgb(59, 90, 91);
    font-weight: 600;
    margin-left: 5px;
  }

  .speaker-icon {
    font-size: 34px !important;
  }

  .search-result-section-headers {
    margin-left: 5px;
    font-size: 20px;
    margin-left: 25px;
  }

  .searched-result-description > ol {
    width: 80%;
  }

  .searched-result-description > ol > li,
  .description-text {
    font-size: 20px;
  }
}
