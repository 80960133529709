@import "../common/variables";
@import "../common/mixins";

.login-wrapper {
  width: 100%;
  height: fit-content;
  min-height: calc(100vh - $header-height);
  max-height: 80vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  display: none;
}

.login-header {
  font-size: 34px !important;
  color: #002d62;
}

.required-text > span {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.required-text > span:nth-child(1) {
  margin-left: 5px;
}
.required-text > span:nth-child(2) {
  width: 100% !important;
  flex-wrap: wrap;
}

.required-text-link {
  text-decoration: underline !important;
  color: blue !important;
  cursor: pointer !important;
}

.login-button {
  margin: 20px auto;
  width: fit-content;
  min-width: 400px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002d62;
  background-color: white;
  outline: none;
  background-color: rgba(265, 265, 265, 0.5);
  border: 0.5px solid #c3c3c3;
  box-shadow: 6px 6px 14px #c3c3c3, -6px -6px 14px #fdfdfd;
  cursor: pointer;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.login-button:hover {
  color: white;
  background-color: #002d62;
  border: 1px solid white;
}

.login-button:disabled {
  cursor: not-allowed;
}

.login-button > span {
  padding-top: 5px;
}

.google-icon {
  margin-right: 10px !important;
}

@media (max-width: 1024px) {
  .login-header {
    font-size: 30px !important;
  }

  .required-text > span {
    font-size: 18px !important;
    font-weight: 500 !important;
  }

  .login-button {
    min-width: 250px !important;
  }
}
